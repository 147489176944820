/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/webpacker and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../src/javascripts/rafael.js.coffee'
import '../src/javascripts/organisms.js.coffee'
import '../src/javascripts/associated_models.js.coffee'
import '../src/javascripts/active_admin.js'
import '../src/javascripts/qtip/jquery.qtip.js'
//require.context('../images', true);

require( 'datatables.net-dt' )
require( 'datatables.net-dt/css/jquery.dataTables.min.css' )

require('datatables.net-select-dt')
require('datatables.net-select-dt/css/select.dataTables.min.css')
require( 'datatables.net-buttons-dt' )
require( 'datatables.net-buttons-dt/css/buttons.dataTables.min.css' )

require( 'datatables.net-searchpanes' )
//require( 'datatables.net-searchpanes-dt' )
require( 'datatables.net-searchpanes-dt/css/searchPanes.dataTables.min.css' )


import Highcharts from 'highcharts';
require("chartkick").use(require("highcharts"))


window.Highcharts = Highcharts;

require("chart.js")

global.$ = require("jquery")
require("@rails/ujs").start()
require("@rails/activestorage").start()

global.echarts = require("echarts/echarts.all.js")

import 'bootstrap'
import "../packs/stylesheets"  // <- Add this line
console.log('Hello World from Webpacker');
